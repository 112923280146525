<template>
  <!-- Start donate Area -->
  <section class="donate-area relative section-gap" id="donate">
    <div class="overlay overlay-bg"></div>
    <div class="container" v-if="!hasError && !showPaymentForm && !showThanks">
      <div class="row d-flex justify-content-end">
        <div class="col-lg-12 col-sm-12 pb-80 header-text">
          <h1>DONATE NOW</h1>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-6 contact-left">
          <div class="single-info">
            <h2>Legal Actions to Demolish Sham Sheep Farm - CROWDFUNDING</h2>
            <p>Moviment Graffitti is planning legal actions for the revocation of the 2019 permit (PA/07946/18) for a sham sheep farm in Bidnija’s ODZ so that this obscenity is demolished and the site returned to its natural state at the developer’s expense.</p>
            <p>We need the financial contribution of the public to cover legal costs.</p>
            <p>Over the past three years, the public has been repulsed at the sight of a massive two-storey structure, fitted with a pool and a reception area, rising in the middle of an untouched valley under the pretext of a ‘sheep farm’; a symbol of the abject failure of our planning system. Moreover, a new, outrageous application (PA/5895/22) has laid bare the real intentions behind the already-approved application, seeking to change the layout and use of the fake farm to officialy become a retail outlet with guest rooms.</p>
            <p>From the outset, it has been crystal clear that this building was not intended for its originally stated use and that it was approved through a series of deceit, lies and malpractice.</p>
            <p>We are determined to have this building demolished through any available legal means. Every donation, small or large, will help us fight the destruction of our environment.</p>
          </div>
        </div>
        <div class="col-lg-6 contact-right">
          <donation-form
              v-on:hasError="handleDonationError"
              v-on:donationCreated="onDonationCreated"
          />
          <img src="img/payment-form-bg.png" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="container" v-if="hasError">
      <errors :error-message="errorMessage"/>
    </div>
    <div class="container" v-if="showPaymentForm">
      <payment-form :details="donationDetails" v-on:paid="onPaid" v-on:paymentError="onPaymentError"/>
    </div>
    <div class="container" v-if="showThanks">
      <thanks-paid :details="paymentDetails"/>
    </div>
  </section>
  <!-- End donate Area -->
</template>

<script>
import DonationForm from "@/components/Donation/DonationForm";
import Errors from "@/components/Errors";
import PaymentForm from "@/components/Donation/PaymentForm";
import ThanksPaid from "@/components/ThanksPaid";
export default {
  name: "Donation",
  components: {ThanksPaid, PaymentForm, Errors, DonationForm},
  data() {
    return {
      hasError: false,
      showPaymentForm: false,
      showThanks: false,
      donationDetails: null,
      errorMessage: null,
      paymentDetails: null
    }
  },
  methods: {
    handleDonationError(error) {
      this.hasError = true
      this.errorMessage = error
    },
    onDonationCreated(response) {
      this.showPaymentForm = true
      this.donationDetails = response
    },
    onPaid(response) {
      this.showPaymentForm = false
      this.showThanks = true
      this.paymentDetails = response
    },
    onPaymentError(response) {
      this.hasError = true
      this.errorMessage = response
    }
  }
}
</script>

<style scoped>
h2 {
  color:#FFFFFF;
  margin-bottom: 0.8em;
}
img {
  margin-top: 1.5em;
}
p {
  font-size: 17px;
  line-height: 1.5;
}
</style>